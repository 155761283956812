@import '../resources/vars';
@import '../resources/mixins';

.container {
  --app-input-container-direction: var(--input-switch-container-direction);
  --app-input-container-align-items: var(--input-switch-container-align-items);
  --app-input-container-justify-content: var(--input-switch-container-justify-content);
  --app-input-container-padding: var(--input-switch-container-padding);
  --app-input-container-border-width: var(--input-switch-container-border-width);
  --app-input-container-border-style: var(--input-switch-container-border-style);
  --app-input-container-border-color: var(--input-switch-container-border-color);

  --app-input-label-direction: var(--input-switch-label-direction);
  --app-input-label-justify-content: var(--input-switch-label-justify-content);
  --app-input-label-align-items: var(--input-switch-label-align-items);
  --app-input-label-margin: var(--input-switch-label-margin);

  --app-input-informer-margin: var(--input-switch-informer-margin);
}

.wrapper,
.body {
  align-items: center;
  display: flex;
}

.body {
  cursor: pointer;
  user-select: none;
}

.body {
  @include outline-control('.input:focus + .body');
  background-color: var(--app-switch-off-body-bg-colour, var(--input-switch-off-body-bg-colour));
  border: var(--app-switch-off-body-border, var(--input-switch-off-body-border));
  border-radius: var(--app-switch-body-border-radius, var(--input-switch-body-border-radius));
  box-shadow: var(--app-switch-off-body-box-shadow, var(--input-switch-off-body-box-shadow));
  box-sizing: content-box;
  height: var(--app-switch-body-height, var(--input-switch-body-height));
  padding: var(--app-switch-body-padding, var(--input-switch-body-padding));
  width: var(--app-switch-body-width, var(--input-switch-body-width));

  &::after {
    background-color: var(--app-switch-off-handle-bg-colour, var(--input-switch-off-handle-bg-colour));
    border: var(--app-switch-off-handle-border, var(--input-switch-off-handle-border));
    border-radius: inherit;
    box-shadow: var(--app-switch-off-handle-box-shadow, var(--input-switch-off-handle-box-shadow));
    box-sizing: border-box;
    content: '';
    display: block;
    height: var(--app-switch-handle-size, var(--input-switch-handle-size));
    overflow: hidden;

    transition: transform 300ms linear;
    width: var(--app-switch-handle-size, var(--input-switch-handle-size));
  }

  @at-root .input:checked {
    + .body {
      background-color: var(--app-switch-on-body-bg-colour, var(--input-switch-on-body-bg-colour));
      border: var(--app-switch-on-body-border, var(--input-switch-on-body-border));
      box-shadow: var(--app-switch-on-body-box-shadow, var(--input-switch-on-body-box-shadow));

      &::after {
        background-color: var(--app-switch-on-handle-bg-colour, var(--input-switch-on-handle-bg-colour));
        border: var(--app-switch-on-handle-border, var(--input-switch-on-handle-border));
        box-shadow: var(--app-switch-on-handle-box-shadow, var(--input-switch-on-handle-box-shadow));

        transform: translateX(100%);
      }
    }
  }

  @at-root .input:disabled {
    + .body {
      opacity: var(--pattern-disabled-opacity);
    }
  }
}

.label {
  color: var(--app-switch-label-colour, var(--input-switch-label-colour));
  font-family: var(--app-switch-label-font-family, var(--input-switch-label-font-family));
  font-size: var(--app-switch-label-font-size, var(--input-switch-label-font-size));
  font-weight: var(--app-switch-label-font-weight, var(--input-switch-label-font-weight));
  font-style: var(--app-switch-label-font-style, var(--input-switch-label-font-style));
  line-height: var(--app-switch-label-line-height, var(--input-switch-label-line-height));
  letter-spacing: var(--app-switch-label-letter-spacing, var(--input-switch-label-letter-spacing));
  text-transform: var(--app-switch-label-text-transform, var(--input-switch-label-text-transform));
  text-align: var(--app-switch-label-text-align, var(--input-switch-label-text-align));
}
