@import '../resources/vars';
@import '../resources/mixins';

.container {
  position: relative;

  &::after {
    border-bottom: var(--checklist-item-keyline);
    content: '';
    display: block;

    @include respond-to(xss) {
      margin: var(--checklist-item-keyline-margin);
    }

    @at-root :global(.sidebar) & {
      margin: var(--checklist-item-keyline-margin);
    }
  }

  &:last-child::after {
    border-bottom: var(--checklist-item-keyline-last);
  }
}

.button {
  align-items: center;
  background-color: var(--checklist-item-btn-bg-colour);
  border: var(--checklist-item-btn-border);
  border-radius: var(--checklist-item-btn-border-radius);
  box-shadow: var(--checklist-item-btn-box-shadow);
  display: flex;
  max-width: var(--checklist-item-btn-max-width);
  min-height: var(--checklist-item-btn-min-height);
  min-width: var(--checklist-item-btn-min-width);
  padding: var(--checklist-item-btn-padding);

  @include respond-to(xss) {
    margin: var(--checklist-item-btn-margin);
  }

  @at-root :global(.sidebar) & {
    margin: var(--checklist-item-btn-margin);
  }

  @at-root .container:first-child & {
    border-top-left-radius: var(--checklist-item-btn-border-radius-first);
    border-top-right-radius: var(--checklist-item-btn-border-radius-first);
  }

  @at-root .container:last-child & {
    border-bottom-left-radius: var(--checklist-item-btn-border-radius-last);
    border-bottom-right-radius: var(--checklist-item-btn-border-radius-last);
  }

  &::after {
    border-left: var(--checklist-item-indicator-width) solid var(--checklist-item-indicator-off-colour);
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    @include respond-to(lg) {
      content: '';
    }
  }

  &:hover::after {
    border-color: var(--checklist-item-indicator-hover-colour);
  }

  &.selected::after {
    border-color: var(--checklist-item-indicator-on-colour);
  }
}

.action,
.status {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

.status {
  background-color: var(--app-custom-checklist-item-bg-colour, var(--checklist-item-checkbox-bg-colour));
  border: var(--app-custom-checklist-item-border, var(--checklist-item-checkbox-border));
  border-radius: var(--checklist-item-checkbox-border-radius);
  color: var(--app-custom-checklist-item-icon-colour, var(--checklist-item-checkbox-icon-colour));
  height: var(--checklist-item-checkbox-bg-height);
  margin: var(--checklist-item-checkbox-bg-margin);
  width: var(--checklist-item-checkbox-bg-width);

  svg {
    height: var(--checklist-item-checkbox-icon-size);
    width: var(--checklist-item-checkbox-icon-size);
  }

  path {
    fill: currentColor;
  }
}

.labels-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--checklist-item-labels-container-padding);
  gap: var(--checklist-item-labels-container-gap);

  @include respond-to(sm, up) {
    padding: var(--checklist-item-labels-container-padding-m);
    gap: var(--checklist-item-labels-container-gap-m);
  }

  @include respond-to(lg, up) {
    padding: var(--checklist-item-labels-container-padding-l);
    gap: var(--checklist-item-labels-container-gap-l);
  }
}

.label {
  color: var(--checklist-item-label-colour);
  font-family: var(--checklist-item-label-font-family);
  font-size: var(--checklist-item-label-font-size);
  font-style: var(--checklist-item-label-font-style);
  font-weight: var(--checklist-item-label-font-weight);
  letter-spacing: var(--checklist-item-label-letter-spacing);
  line-height: var(--checklist-item-label-line-height);
  text-align: var(--checklist-item-label-align);
  text-transform: var(--checklist-item-label-text-transform);

  @include respond-to(sm, up) {
    font-size: var(--checklist-item-label-font-size-m);
  }

  @include respond-to(lg, up) {
    font-size: var(--checklist-item-label-font-size-l);
  }
}

.status-label {
  color: var(--checklist-item-status-label-colour);
  font-family: var(--checklist-item-status-label-font-family);
  font-size: var(--checklist-item-status-label-font-size);
  font-style: var(--checklist-item-status-label-font-style);
  font-weight: var(--checklist-item-status-label-font-weight);
  letter-spacing: var(--checklist-item-status-label-letter-spacing);
  line-height: var(--checklist-item-status-label-line-height);
  text-align: var(--checklist-item-status-label-align);
  text-transform: var(--checklist-item-status-label-text-transform);

  @include respond-to(sm, up) {
    font-size: var(--checklist-item-status-label-font-size-m);
  }

  @include respond-to(lg, up) {
    font-size: var(--checklist-item-status-label-font-size-l);
  }
}

.action {
  background-color: var(--btn-menu-icon-bg-color);
  border: var(--checklist-item-action-bg-border);
  border-radius: var(--checklist-item-action-bg-border-radius);
  height: var(--checklist-item-action-bg-height);
  width: var(--checklist-item-action-bg-width);

  svg {
    height: var(--checklist-item-action-icon-size);
    width: var(--checklist-item-action-icon-size);
  }

  path {
    fill: var(--checklist-item-action-icon-color);
  }
}
