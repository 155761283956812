@import '../../resources/vars';
@import '../../resources/mixins';

.wrapper {
  padding: var(--story-slide-padding);

  --app-card-slider-card-height: calc(100dvh - var(--app-custom-top-nav-height) - var(--app-progress-bar-height) - var(--app-card-slider-button-height));
  --app-card-slider-card-width: calc(var(--app-card-slider-card-height) * var(--pt-story-slides-aspect-ratio));

  @include respond-to(sm, up) {
    --app-card-slider-card-height: calc(100dvh - var(--app-custom-top-nav-height) - var(--app-progress-bar-height));
    --app-card-slider-card-width: calc(var(--app-card-slider-card-height) * var(--pt-story-slides-aspect-ratio-m));
  }

  @include respond-to(lg, up) {
    --app-card-slider-card-height: calc(100dvh - var(--app-custom-top-nav-height) - var(--app-progress-bar-height));
    --app-card-slider-card-width: calc(var(--app-card-slider-card-height) * var(--pt-story-slides-aspect-ratio-l));
  }
}

.step {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background: var(--story-slide-background);
  border-radius: var(--story-slide-border-radius);
  border: var(--story-slide-border);
  box-shadow: var(--story-slide-box-shadow);

  @include respond-to(sm, up) {
    border: var(--story-slide-border-m, var(--story-slide-border));
  }

  @include respond-to(lg, up) {
    border: var(--story-slide-border-l, var(--story-slide-border));
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
